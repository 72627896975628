<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Scan Picking</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <div class="bg-secondary p-6 mb-2 rounded-lg">
          <v-row v-if="currentItem">
            <v-col cols="auto">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0">
                  System Order No:
                </v-col>
                <v-col>
                  <div>{{ currentItem.system_order_number }}</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0">
                  Picking type:
                </v-col>
                <v-col>
                  <div>{{ currentItem.picking_type_label }}</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0">
                  Pickable quantity:
                </v-col>
                <v-col>
                  <div>{{ currentItem.pickable_qty }}</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto" v-if="currentItem.totes">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0">
                  Totes:
                </v-col>
                <v-col>
                  <div>{{ currentItem.totes }}</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="bg-secondary p-6 mb-2 rounded-lg">
          <v-row v-if="currentItem">
            <v-col cols="auto">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0"> SKU: </v-col>
                <v-col>
                  <div>{{ currentItem.sku_component.text1 }}</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0">
                  Suggested location:
                </v-col>
                <v-col>
                  <div>{{ currentItem.location_label }}</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="bg-secondary p-6 mb-8 rounded-lg">
          <v-row v-if="currentItem">
            <v-col cols="auto">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0">
                  Design:
                </v-col>
                <v-col>
                  <div>{{ currentItem.sku_component.design_number }}</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0">
                  Size:
                </v-col>
                <v-col>
                  <div>{{ currentItem.sku_component.size }}</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0">
                  Description:
                </v-col>
                <v-col>
                  <div>{{ currentItem.sku_component.text2 }}</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <!--begin::Body-->
        <div class="h-75px">
          <v-text-field
            ref="location"
            v-model="formData.location"
            label="Location"
            clearable
            type="text"
            outlined
            dense
            append-icon="mdi-barcode-scan"
            :error-messages="locationErrors"
            @click:append="handleLocationInput"
            @keyup.enter="handleLocationInput"
            @input="$v.formData.location.$touch()"
            @blur="$v.formData.location.$touch()"
          ></v-text-field>
        </div>
        <div class="h-75px">
          <v-text-field
            ref="sku"
            v-model="formData.sku"
            label="SKU"
            clearable
            type="text"
            outlined
            dense
            :disabled="disabled.sku"
            append-icon="mdi-barcode-scan"
            :error-messages="skuErrors"
            @click:append="handleSkuInput"
            @keyup.enter="handleSkuInput"
            @input="$v.formData.sku.$touch()"
            @blur="$v.formData.sku.$touch()"
          ></v-text-field>
        </div>
        <div class="h-75px">
          <v-text-field
            ref="quantity"
            v-model="formData.quantity"
            label="Quantity"
            clearable
            type="number"
            outlined
            dense
            :disabled="disabled.quantity"
            append-icon="mdi-barcode-scan"
            :error-messages="quantityErrors"
            @click:append="handleQuantityInput"
            @keyup.enter="handleQuantityInput"
            @change="handleQuantityInput"
            @input="$v.formData.quantity.$touch()"
            @blur="onBlurQty"
          ></v-text-field>
        </div>
        <div class="h-75px">
          <v-text-field
            ref="tote"
            v-model="formData.tote"
            label="Tote"
            clearable
            type="text"
            outlined
            dense
            :disabled="disabled.tote"
            append-icon="mdi-barcode-scan"
            :error-messages="toteErrors"
            @click:append="handleToteInput"
            @keyup.enter="handleToteInput"
            @input="$v.formData.tote.$touch()"
            @blur="$v.formData.tote.$touch()"
          ></v-text-field>
        </div>
        <!--begin::Actions-->
        <div class="my-4 d-flex justify-content-between">
          <button
            :disabled="!previousItem"
            class="btn btn-sm btn-info"
            @click="previous"
          >
            Previous
          </button>
          <div>
            <button
              v-if="permissionChecker('skip_task')"
              class="btn btn-secondary px-5 py-3 ls1"
              @click="skip"
            >
              Skip
            </button>
            <button
              ref="submit"
              type="submit"
              :disabled="!toteValidated"
              class="btn btn--export-filter px-5 py-3 ls1 mx-5"
              @click="save"
            >
              Submit
            </button>
          </div>
          <button
            :disabled="!nextItem"
            class="btn btn-sm btn-info px-4"
            @click="next"
          >
            Next
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <v-snackbar
        v-model="navigationAlert"
        :timeout="navigationAlertTimeout"
        class="mb-10"
      >
        {{ navigationMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="navigationAlert = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { SET_CURRENT_ITEM } from "@/core/services/store/picking2.module";
import { getToken } from "@/core/services/jwt.service";
import axios from "axios";

export default {
  name: "ScanPicking",
  mixins: [validationMixin],
  validations: {
    formData: {
      location: { required },
      sku: { required },
      tote: { required },
      quantity: { required },
    },
  },
  props: {
    refresher: {
      required: true,
      type: Function,
    },
  },
  data: () => ({
    dialog: false,
    id: null,
    formData: {
      location: null,
      sku: null,
      tote: null,
      quantity: 1,
    },
    disabled: {
      sku: true,
      tote: true,
      quantity: true,
    },
    toteValidated: false,
    navigationAlert: false,
    navigationMessage: "Navigated to next",
    navigationAlertTimeout: 1500,
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    toggleModal(item) {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.clearFields();
        this.formData.location = null;
        this.$store.commit(SET_CURRENT_ITEM, null);
        this.refresher();
      } else {
        this.$store.commit(SET_CURRENT_ITEM, item.id);
        this.dialog = !this.dialog;
        setTimeout(() => {
          this.$refs.location.focus();
        });
      }
    },
    handleLocationInput() {
      if (this.formData.location) {
        if (this.formData.location === this.currentItem.location_label) {
          //check locked location
          this.$store.commit(SET_PAGE_LOADING, true);
          const payload = {
            warehouse_id: this.selectedWarehouse,
            location: this.formData.location,
          };
          ApiService.post(
            `/warehouse_management/warehouses/check_locked_location`,
            payload
          )
            .then(() => {
              this.disabled.sku = false;
              setTimeout(() => {
                this.$refs.sku.focus();
              });
            })
            .finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        } else {
          Swal.fire({
            title: "Invalid Input",
            text: "This location doesn't exits",
            icon: "error",
            showConfirmButton: true,
          });
        }
      }
    },
    handleSkuInput() {
      if (this.formData.sku) {
        if (this.formData.sku === this.currentItem.sku_component.text1) {
          this.disabled.quantity = false;
          setTimeout(() => {
            this.$refs.quantity.focus();
          });
        } else {
          Swal.fire({
            title: "Invalid Input",
            text: "This SKU doesn't exits",
            icon: "error",
            showConfirmButton: true,
          });
        }
      }
    },
    handleToteInput() {
      // set page loading
      this.$store.commit(SET_PAGE_LOADING, true);
      //prepare data for request
      const data = {
        id: this.currentItem.id,
        sku: this.currentItem.sku_component.text1,
        tote_id: this.formData.tote,
      };
      //request
      ApiService.post(`/warehouse_management/picking/validate_tote`, data)
        .then(() => (this.toteValidated = true))
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    handleQuantityInput() {
      if (this.formData.quantity > this.currentItem.pickable_qty) {
        Swal.fire({
          title: "Invalid Input",
          text: "Quantity limit exceeds.",
          icon: "error",
          showConfirmButton: true,
        });
        // this.formData.quantity = this.currentItem.pickable_qty;
      } else {
        this.disabled.tote = false;
        setTimeout(() => {
          this.$refs.tote.focus();
        });
      }
    },
    async save() {
      this.$v.$touch();
      //check if all fields valid
      const areFalse = Object.values(this.disabled).every(
        (value) => value === false
      );
      if (this.$v.$invalid || !areFalse) {
        return;
      } else {
        //check quantity validity
        if (this.formData.quantity > this.currentItem.pickable_qty) {
          this.handleQuantityInput();
          return;
        }
        //next available item
        const nextItem = this.nextItem ? { ...this.nextItem } : null;
        // set page loading
        this.$store.commit(SET_PAGE_LOADING, true);

        const data = {
          picked_qty: this.formData.quantity,
          id: this.currentItem.id,
          location: this.formData.location,
          sku: this.formData.sku,
          tote_id: this.formData.tote,
        };

        try {
          await ApiService.post(`/warehouse_management/picking/update`, data);
          Swal.fire({
            title: "Updated",
            text: "Picking status updated",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.clearFields();
          await this.refresher();
          this.$store.commit(SET_CURRENT_ITEM, this.currentItem.id);
          // this.disabled.sku = false;
          if (!this.currentItem) {
            if (nextItem) {
              this.$store.commit(SET_CURRENT_ITEM, nextItem.id);
            } else {
              this.toggleModal();
              this.formData.location = null;
            }
          }
        } catch {
          //
        } finally {
          this.$store.commit(SET_PAGE_LOADING, false);
          // setTimeout(() => {
          //   this.$refs.location.focus();
          // }, 2501);
        }
      }
    },
    async skip() {
      //next available item
      const nextItem = this.nextItem ? { ...this.nextItem } : null;
      const payload = {
        task_id: this.currentItem.id,
      };
      // set page loading
      this.$store.commit(SET_PAGE_LOADING, true);
      let config = {
        method: "post",
        url: `${process.env.VUE_APP_BASE_URL}/warehouse_management/picking/skip_task`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        data: payload,
      };
      axios(config)
        .then(async (data) => {
          if (data?.status === 200) {
            // notify
            this.snackbarNotification("Order is skipped");
            await this.refresher();
            this.$store.commit(SET_CURRENT_ITEM, this.currentItem.id);
            this.disabled.sku = false;
            if (!this.currentItem) {
              if (nextItem) {
                this.$store.commit(SET_CURRENT_ITEM, nextItem.id);
              } else {
                this.toggleModal();
                this.formData.location = null;
              }
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            this.next();
            return;
          }
          // this.errorMessage("Something went wrong!", "error", "Error");
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });

      // try {
      //   // set page loading
      //   this.$store.commit(SET_PAGE_LOADING, true);
      //   const response = await ApiService.post(
      //     `/warehouse_management/picking/skip_task`,
      //     payload
      //   );
      //   if (response?.code === 400) {
      //     this.next();
      //     return;
      //   }
      //   // notify
      //   this.snackbarNotification("Order is skipped");
      //   await this.refresher();
      //   this.$store.commit(SET_CURRENT_ITEM, this.currentItem.id);
      //   this.disabled.sku = false;
      //   if (!this.currentItem) {
      //     if (nextItem) {
      //       this.$store.commit(SET_CURRENT_ITEM, nextItem.id);
      //     } else {
      //       this.toggleModal();
      //       this.formData.location = null;
      //     }
      //   }
      // } catch {
      //   //
      // } finally {
      //   this.$store.commit(SET_PAGE_LOADING, false);
      //   setTimeout(() => {
      //     this.$refs.sku.focus();
      //   }, 2501);
      // }
    },
    snackbarNotification(msg, timeout = 1500) {
      this.navigationAlert = false; // if any previous notification raised
      setTimeout(() => {
        this.navigationAlert = true;
        this.navigationMessage = msg;
        this.navigationAlertTimeout = timeout;
      }, 200);
    },
    clearFields() {
      this.formData = {
        // ...this.formData,
        location: null,
        sku: null,
        tote: null,
        quantity: 1,
      };
      this.disabled = {
        sku: true,
        tote: true,
        quantity: true,
      };
      this.toteValidated = false;
      this.$v.$reset();
      this.$refs.location.focus();
    },
    previous() {
      const previousItem = { ...this.previousItem };
      if (previousItem) {
        this.clearFields();
        this.$store.commit(SET_CURRENT_ITEM, previousItem.id);
        this.snackbarNotification("Previous item loaded.");
      }
    },
    next() {
      const nextItem = { ...this.nextItem };
      if (nextItem) {
        this.clearFields();
        this.$store.commit(SET_CURRENT_ITEM, nextItem.id);
        this.snackbarNotification("Next item loaded.");
      } else {
        this.toggleModal();
      }
    },
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    onBlurQty() {
      this.$v.formData.quantity.$touch();
      if (this.formData.quantity < 1) {
        this.formData.quantity = 1;
      }
    },
  },
  computed: {
    wmsPickings: function () {
      return this.$store.getters.getWMSPICKINGTableData.data;
    },
    locationErrors: function () {
      return this.handleFormValidation("location", this);
    },
    skuErrors: function () {
      return this.handleFormValidation("sku", this);
    },
    toteErrors: function () {
      return this.handleFormValidation("tote", this);
    },
    quantityErrors: function () {
      return this.handleFormValidation("quantity", this);
    },
    currentItem: function () {
      return this.$store.getters.getWMSPICKINGCurrentItem;
    },
    nextItem: function () {
      return this.$store.getters.getWMSPICKINGNextItem;
    },
    previousItem: function () {
      return this.$store.getters.getWMSPICKINGPreviousItem;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    table_permissions: function () {
      if (this.$store.getters.getWMSPICKINGTablePermissions) {
        return this.$store.getters.getWMSPICKINGTablePermissions.map(function (
          perm
        ) {
          return perm.name;
        });
      } else {
        return false;
      }
    },
  },
};
</script>
